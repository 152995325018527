import styled from '@emotion/styled';
import { richTextElements, richTextParagraphElements } from 'constants/common';
import { MEDIA_QUERIES } from 'globalStyles';
import { themev2 as theme, themev2 } from 'theme-v2';
import { COMPACT_HEADER_MEDIA_QUERY } from '../Header/constants';
import { IFooter } from './constants';
import { hexToRgba } from '@smartproxy-web/shared/utils';

export const StyledFooter = styled.footer({
  background: theme.colors.neutral.N99,
  padding: '0 20px',
});

export const StyledTitle = styled.p({
  fontFamily: theme.fonts.family.main,
  fontSize: theme.fonts.size.p_xsmall,
  lineHeight: theme.fonts.lineHeight.p_xsmall,
  textTransform: 'uppercase',
  color: theme.colors.neutral.N50,
  fontWeight: 400,
  letterSpacing: '0.1em',
  marginBottom: theme.spacings.XS,
});

export const StyledFooterContainer = styled.div<IFooter>((isDealsOrLpPage) => ({
  margin: '0 auto',
  maxWidth: '1024px',
  display: 'flex',
  gap: '80px',
  borderTop: `1px solid ${hexToRgba(theme.colors.neutral.N00, 0.1)}`,
  padding: isDealsOrLpPage
    ? `${theme.spacings.L} 0`
    : `${theme.spacings.L} 0 0`,
  [COMPACT_HEADER_MEDIA_QUERY]: {
    padding: isDealsOrLpPage ? '32px 20px' : `${theme.spacings.L} 20px`,
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    gap: '32px',
    flexDirection: 'column-reverse',
    paddingTop: 0,
  },
}));

export const StyledNavItemWrapper = styled.div({
  flexGrow: 1,
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, 120px)',
  paddingLeft: '80px',
  gap: '48px',
  marginTop: '60px',
  borderLeft: `1px solid ${hexToRgba(theme.colors.neutral.N00, 0.1)}`,
  height: 'auto',

  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    gridTemplateColumns: 'repeat(2, 120px)',
    paddingLeft: 0,
    gap: `${theme.spacings.S} 48px`,
    marginTop: '32px',
    borderLeft: 'none',
  },
});

export const StyledNavItemCategoryWrapper = styled.div({
  [richTextElements]: {
    letterSpacing: '0.1em',
    fontWeight: 400,
    textTransform: 'uppercase',
    color: theme.colors.neutral.N50,
    fontSize: theme.fonts.size.p_xsmall,
    marginBottom: theme.spacings.XS,
  },
});

export const List = styled.ul({
  padding: 0,
  listStyle: 'none',
  li: {
    paddingBottom: '12px',
    ':last-of-type': {
      paddingBottom: 0,
    },
  },
  a: {
    color: theme.colors.white.default,
    fontSize: theme.fonts.size.p_small,
    lineHeight: theme.fonts.lineHeight.p_small,
    fontWeight: 400,
    opacity: 0.8,
    ':hover': {
      opacity: 1,
    },
  },
});

export const StyledLinkItem = styled.li({
  paddingBottom: '2px',
  opacity: 0.8,
  a: {
    display: 'flex',
    gap: 8,
    fontSize: themev2.fonts.size.p_small,
    svg: {
      width: 18,
      height: 18,
    },
  },
  ':hover': {
    opacity: 1,
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    padding: 0,
  },
});

export const StyledLinkItemLabel = styled.p({
  color: theme.colors.neutral.N00,
});

export const LogoContainer = styled.div({
  margin: '0 auto',
  marginBottom: '36px',
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    marginBottom: theme.spacings.S,
    paddingTop: '32px',
    borderTop: '1px solid rgba(255,255,255, .1)',
  },
});

export const StyledActionsWrapper = styled.div({
  flexShrink: 0,
  [MEDIA_QUERIES.TABLET]: {
    marginLeft: 0,
  },
});

export const StyledActionColumns = styled.div({
  display: 'flex',
  gap: '60px',
});

export const StyledActionColumn = styled.div({
  '> *+*': {
    marginTop: 40,
  },
});

export const LinksContainer = styled.ul<{
  direction?: 'row' | 'column';
  isDealsOrLpPage: boolean;
  itemsNumber: number;
}>(({ direction = 'column', isDealsOrLpPage, itemsNumber }) => ({
  padding: 0,
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '12px',
  listStyle: 'none',
  ...(direction === 'row' && {
    gridTemplateColumns: isDealsOrLpPage
      ? `repeat(${itemsNumber}, auto)`
      : 'repeat(4, auto)',
    [COMPACT_HEADER_MEDIA_QUERY]: {
      gridTemplateColumns: 'repeat(4, auto)',
    },
  }),
}));

export const StyledLinkTrigger = styled.button<{ hasQrCode?: boolean }>(
  ({ hasQrCode }) => ({
    textAlign: 'left',
    background: 'transparent',
    border: 'none',
    padding: 0,
    display: 'flex',
    gap: 8,
    ...(hasQrCode && { cursor: 'pointer' }),

    svg: {
      flexShrink: 0,
    },

    ':focus': {
      outline: 'initial',
    },
  })
);

export const StyledQRCodeWrapper = styled.div({
  backgroundColor: theme.colors.neutral.N00,
  height: '120px',
  width: '120px',
  padding: '10px',
  borderRadius: '6px',

  '[data-gatsby-image-wrapper]': {
    width: '100%',
    height: '100%',
  },
});

export const FooterDisclaimer = styled.div({
  margin: '0 auto',
  maxWidth: '1024px',
  display: 'flex',
  textAlign: 'center',
  marginTop: theme.spacings.L,
  borderTop: `1px solid ${hexToRgba(theme.colors.neutral.N00, 0.1)}`,

  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    marginTop: '32px',
  },
});

export const FooterDisclaimerInner = styled.div({
  padding: `60px 20px`,
  [richTextParagraphElements]: {
    color: theme.colors.neutral.N50,
    fontSize: theme.fonts.size.p_xsmall,
    lineHeight: theme.fonts.lineHeight.p_xsmall,
    fontWeight: 300,
  },

  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    padding: `32px 20px`,
  },
});

export const BottomBanner = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: '8px',
  background: theme.colors.black.S600,
  padding: '18px 0',
  textAlign: 'center',
  [richTextParagraphElements]: {
    lineHeight: theme.fonts.lineHeight.p_xsmall,
    fontSize: theme.fonts.size.p_xsmall,
    fontWeight: 300,
    fontFamily: theme.fonts.family.main,
    color: theme.colors.neutral.N50,
    transition: 'all 0.3s ease-in-out',
  },
  'a:hover': {
    color: theme.colors.neutral.N00,
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    textAlign: 'left',
    paddingLeft: '20px',
  },
});
